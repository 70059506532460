import React from "react"
import { FaWaze, FaMapMarkerAlt } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Jumbotron, Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const Hero = styled(Jumbotron)`
  text-align: center;
  margin-bottom: 0;
  border-radius: 0;
`

const LocationPage = () => {
  return (
    <Layout removeContainer>
      <SEO title='Location' />

      <Hero className='bg-gradient'>
        <h1 className='text-white'>Our Location</h1>
      </Hero>
      <Container className='py-5'>
        <Row>
          <Col lg='4'>
            <div className='mb-4'>
              <h4>Address</h4>
              <p>
                76, Jalan Bulan U5/172 (U5/10), Seksyen U5, Bandar Pinggiran
                Subang, 40150, Shah Alam, Selangor, 40150
              </p>
            </div>

            <div className='mb-4'>
              <h4>Email</h4>
              <p>
                <a href='mailto:customerhelp@makenta.com.my' className='my-2'>
                  customerhelp@makenta.com.my
                </a>
              </p>
            </div>

            <div className='mb-4'>
              <h4>Contact No.</h4>
              <p>
                <a
                  rel='noopener noreferrer'
                  href='tel:60378439806'
                  className='my-2'
                >
                  +60(3)-7843 9806
                </a>
                <br />
                <a
                  rel='noopener noreferrer'
                  href='tel:60378439877'
                  className='my-2'
                >
                  +60(3)-7843 9877
                </a>
              </p>
            </div>

            <div className='mb-4'>
              <h4 className='mb-0'>
                <FaWaze className='mr-2 mb-2' />
                Waze
              </h4>

              <a
                rel='noopener noreferrer'
                href='https://www.waze.com/ul?place=ChIJRaTX17ZPzDERyvt41jOsETQ&ll=3.16046010%2C101.55266040&navigate=yes'
                target='_blank'
              >
                Get Directions In Waze
              </a>
            </div>
            <div className='mb-4'>
              <h4 className='mb-0'>
                <FaMapMarkerAlt className='mr-2 mb-2' />
                Google Maps
              </h4>
              <a
                rel='noopener noreferrer'
                href='https://goo.gl/maps/W3FceRRxQjVtgafG6'
                target='_blank'
              >
                Get Directions In Google Maps
              </a>
            </div>
          </Col>
          <Col lg='8'>
            <iframe
              title='google-map'
              className='shadow'
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.00000761879!2d101.5526604!3d3.1604601!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3411ac33d678fbca!2sMakenta%20Engineering%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1584453746496!5m2!1sen!2smy'
              width='100%'
              height='375px'
              frameborder='0'
              css={css`
                border: 0;
              `}
              allowfullscreen=''
              aria-hidden='false'
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default LocationPage
